import * as React from "react";

const MediumLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    imageRendering="optimizeQuality"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z" />
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M274.898 255.999c0 46.265-37.251 83.772-83.202 83.772-45.952 0-83.199-37.496-83.199-83.772 0-46.277 37.25-83.77 83.199-83.77s83.202 37.504 83.202 83.77zm91.273 0c0 43.553-18.626 78.857-41.601 78.857-22.974 0-41.601-35.316-41.601-78.857 0-43.542 18.627-78.857 41.601-78.857 22.975 0 41.601 35.315 41.601 78.857zm37.332 0c0 39.021-6.551 70.652-14.631 70.652s-14.632-31.642-14.632-70.652c0-39.01 6.552-70.653 14.634-70.653 8.083 0 14.629 31.634 14.629 70.653z"
    />
  </svg>
);

export default MediumLogo;
